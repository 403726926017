<template>
  <b-row>
    <b-table :items="notes" :fields="['title', 'description', 'visibility']">
      <template #cell(description)="data">
        {{ data.value ? (data.value.length > 120 ? data.value.substr(0, 120) + '...' : data.value) : '' }}
      </template>
      <template #cell(author)="data">
        <span v-if="!data.value || !data.value.name">No Author</span>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BCol,
  BLink,
  BBadge,
  BRow,
} from 'bootstrap-vue';
import moment from 'moment';

export default {
  name: 'ProfileNoteItem',
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BCol,
    BLink,
    BBadge,
    BRow,
  },
  props: {
    notes: {
      type: Array,
    },
  },
  setup(props) {
    return {
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
