<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="profileData === undefined">
      <h4 class="alert-heading">Error fetching profile data</h4>
      <div class="alert-body">
        No profile found with this profile id. Check
        <b-link class="alert-link" :to="{ name: 'apps-placement-profiles-list' }"> Profile List </b-link>
        for other profiles.
      </div>
    </b-alert>

    <template v-if="profileData">
      <b-row>
        <b-col cols="12" lg="5">
          <profile-view-student-info-card :profile-data="profileData" />
          <profile-view-student-plan-card :profile-data="profileData" />
        </b-col>

        <b-col cols="12" lg="7">
          <profile-view-student-permissions-card :profile-data="profileData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue';
import placementStoreModule from '../placementStoreModule';
import ProfileViewStudentInfoCard from './ProfileViewStudentInfoCard.vue';
import ProfileViewStudentPlanCard from './ProfileViewStudentPlanCard.vue';
import ProfileViewStudentTimelineCard from './ProfileViewStudentTimelineCard.vue';
import ProfileViewStudentPermissionsCard from './ProfileViewStudentPermissionsCard.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ProfileViewStudentInfoCard,
    ProfileViewStudentPlanCard,
    ProfileViewStudentTimelineCard,
    ProfileViewStudentPermissionsCard,
  },
  setup() {
    const profileData = ref(null);

    const PROFILE_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME))
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, placementStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-placement/fetchProfile', {
        id: router.currentRoute.params.id,
        queryParams: {
          populate: 'student profile notes.author',
        },
      })
      .then((response) => {
        profileData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          profileData.value = undefined;
        }
      });

    return {
      profileData,
    };
  },
};
</script>

<style></style>
