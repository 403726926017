<template>
  <b-row>
    <b-table :items="certificates">
      <template #cell(description)="data">
        {{ data.value ? (data.value.length > 45 ? data.value.substr(0, 45) + '...' : data.value) : '' }}
      </template>
      <template #cell(expiresAt)="data">
        {{ data.value ? moment(data.value).format('MMM D YYYY') : 'Never' }}
      </template>
    </b-table>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BCol,
  BLink,
  BBadge,
  BRow,
} from 'bootstrap-vue';
import moment from 'moment';

export default {
  name: 'ProfileCertificationItem',
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BCol,
    BLink,
    BBadge,
    BRow,
  },
  props: {
    certificates: {
      type: Array,
    },
  },
  setup(props) {
    return {
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
