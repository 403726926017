<template>
  <b-card>
    <b-row>
      <!-- Profile Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- Profile Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="profileData.student.avatar"
            :text="avatarText(profileData.student.fullName)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ profileData.student.fullName }}
              </h4>
              <span class="card-text">{{ profileData.student.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-placement-profile-edit', params: { id: profileData.id } }"
                variant="primary"
                block
              >
                Edit
              </b-button>
              <b-button v-if="false" variant="outline-danger" class="ml-1"> Delete </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="HashIcon" class="mr-75" />
              <span class="font-weight-bold">Student Id</span>
            </th>
            <td class="pb-50">
              <b-link
                :to="{ name: 'apps-students-view', params: { id: profileData.student.id } }"
                class="font-weight-bold text-nowrap"
              >
                {{ profileData.student.studentId }}
              </b-link>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Recommended</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ profileData.recommended ? 'Yes' : 'No' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ profileData.status }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BLink } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BLink,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyElementToClipboard(id) {
      this.$copyText(this.profileData[id])
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Copied ${id} to clipboard!`,
              icon: 'BellIcon',
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not copy!',
              icon: 'BellIcon',
            },
          });
        });
    },
  },
  setup() {
    return {
      avatarText,
    };
  },
};
</script>

<style></style>
