<template>
  <b-col>
    <b-card>
      <b-card-body class="p-0 w-100">
        <b-tabs content-class="mt-2" pills lazy>
          <b-tab title="Certifications">
            <profile-certification-item :certificates="profileData.certificates" />
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body class="p-0 w-100">
        <b-tabs content-class="mt-2" pills lazy>
          <b-tab title="Notes">
            <profile-note-item :notes="profileData.notes" />
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import { BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BTab, BTabs, BRow, BCol } from 'bootstrap-vue';
import { barePermissionsData, initialAbility, permissionsData } from '@/libs/acl/config';
import { title } from '@core/utils/filter';
import ProfileCertificationItem from '@/views/apps/placement/profile-view/components/ProfileCertificationItem';
import ProfileNoteItem from '@/views/apps/placement/profile-view/components/ProfileNoteItem';

export default {
  components: {
    ProfileNoteItem,
    ProfileCertificationItem,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BRow,
    BCol,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const applyAbility = (permissions, abilities) => {
      let modules = { ...permissions };

      for (let ability of abilities) {
        if (ability.subject === 'system') continue;
        if (!modules[ability.subject])
          modules[ability.subject] = {
            read: false,
            write: false,
            create: false,
            delete: false,
          };
        modules[ability.subject][ability.action] = true;
      }

      return Object.keys(modules).map((moduleName) => {
        return { module: moduleName, ...modules[moduleName] };
      });
    };

    let abilities = [...initialAbility];

    if (props.profileData.ability) {
      abilities.push(...props.profileData.ability);
    }

    let pmd = applyAbility({}, abilities);

    return {
      permissionsData: pmd,
      title,
    };
  },
};
</script>

<style></style>
